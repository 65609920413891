<template>
  <modal class="modal-add-point" :modal="modal" @changeModal="e => (modal = e)">
    <div class="modal__content">
      <div class="modal__header">
        <h2 class="modal__title">
          Adicionar novo ponto de coleta
        </h2>
      </div>
      <div class="modal__form--group">
        <VinculationType v-model="form.role" />
      </div>

      <div class="row" v-if="form.role">
        <r-select
          :label="`${selectableLabel}:`"
          label-name="name"
          value-name="id"
          v-model="form.user_id"
          :items.sync="selectable"
          class="col-12"
          :placeholder="`Selecionar ${selectableLabel}`"
          :noOptions="
            `Sem ${selectableLabel.toLowerCase()} disponível para seleção.`
          "
        />

        <template v-if="form.user_id">
          <r-input
            v-model="form.name"
            label="Nome"
            type="text"
            class="col-12"
          />

          <r-input
            v-model="form.cep"
            label="CEP"
            mask="#####-###"
            class="col-12"
          />

          <r-input v-model="form.street" label="Rua" class="col-12" />
          <r-input v-model="form.number" label="Número" class="col-12" />
          <r-input v-model="form.district" label="Bairro" class="col-12" />
          <r-input
            v-model="form.complement"
            class="col-12"
            label="Complemento"
          />

          <r-input
            v-model="form.value"
            class="col-12"
            label="Valor"
            type="money"
          />

          <div class="footer_modal">
            <button
              type="button"
              @click.prevent="onSave()"
              class="button_outline"
            >
              {{ saving ? 'Salvando...' : 'Salvar' }}
            </button>
          </div>
        </template>
      </div>
    </div>
  </modal>
</template>

<script>
import Vue from 'vue'
import swal from 'sweetalert'
import { mapGetters, mapActions } from 'vuex'

const defaultForm = {
  city_id: '',
  item_id: '',
  name: '',
  cep: '',
  street: '',
  number: '',
  district: '',
  complement: '',
  email: '',
  validEmail: '',
  role: '',
  user_id: '',
  value: 0
}

export default Vue.extend({
  name: 'add-collect-point',
  data: () => ({
    saving: false,
    form: { ...defaultForm },
    data: [],
    labels: {
      entidade: 'Entidade',
      'gerador-ponto': 'Gerador',
      'coletor-point': 'Ponto'
    }
  }),
  props: ['value', 'itemId', 'cityId'],
  methods: {
    ...mapActions('province', { fetchProvinces: 'fetch' }),
    ...mapActions('city', ['fetch', 'getViaCEP']),
    async onSave () {
      if (!this.isValid) {
        swal({
          title: 'Ooops!',
          text: 'Preencha todos os campos corretamente.',
          icon: 'warning',
          buttons: {
            confirmButton: { visible: true, text: 'OK' }
          }
        })
        return
      }

      this.saving = true
      try {
        let url = 'auth/register/collect-point'
        if (this.form.id) url += `/${this.form.id}`
        const method = this.form.id ? 'PUT' : 'POST'

        this.form.validEmail = this.validEmail
        this.form.email = this.validEmail

        await this.$http({ url, method, data: this.form })

        this.modal = false

        swal({
          title: 'Sucesso!',
          text: 'Ponto de coleta cadastrado com sucesso.',
          icon: 'success',
          buttons: false,
          timer: 2000
        })
      } catch (error) {
        const { data, status } = error.response
        swal({
          title: 'Ooops!',
          text: data.message,
          icon: status >= 500 ? 'error' : 'warning'
        })
      } finally {
        this.saving = false
      }
    },
    async getCollectors () {
      if (this.data.length) return
      const url = 'check/getCollectors'
      const { data } = await this.$http.post(url)
      this.data = data.data
    },
    resetForm () {
      this.form = { ...defaultForm }

      this.form.item_id = this.itemId
      this.form.city_id = this.cityId
    }
  },
  mounted () {
    this.getCollectors()
  },
  computed: {
    ...mapGetters('province', ['provinces']),
    ...mapGetters('city', ['cities']),
    modal: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    isValid () {
      const f = this.form

      if (!f.role) return false
      if (!f.user_id) return false
      if (!f.name) return false
      if (!f.cep) return false
      if (!f.street) return false
      if (!f.number) return false
      if (!f.district) return false
      if (!this.validEmail) return false

      return true
    },
    selectableLabel () {
      if (!this.form.role) return ''
      return `${this.labels[this.form.role]}`
    },
    selectable () {
      return this.data.filter(({ role, cities }) => {
        return role === this.form.role && cities.includes(this.form.city_id)
      })
    },
    validEmail () {
      if (!this.form.role || !this.form.user_id || !this.data.length) return ''
      const [user] = this.data.filter(
        ({ id, role }) => id === this.form.user_id && role === this.form.role
      )
      return user ? user.email : ''
    }
  },
  components: {
    Modal: () => import('../../../components/modal.vue'),
    RSelect: () => import('../../../components/select.vue'),
    RInput: () => import('../../../components/input.vue'),
    VinculationType: () => import('./vinculation-type')
  },
  watch: {
    modal () {
      this.resetForm()
    },
    async 'form.cep' (val) {
      if (!val || val.length < 8) return
      const data = await this.getViaCEP({ zipCode: val })
      this.form = Object.assign(this.form, data)
      Object.keys(data).map(item => {
        this.loadedByZip[item] = !!data[item]
      })
    },
    'form.role' (val) {
      this.form = {
        ...defaultForm,
        role: val,
        city_id: this.form.city_id,
        item_id: this.form.item_id
      }
      if (!val) return this.resetForm()
      if (val === 'coletor-point') return this.getCollectors()
    }
  }
})
</script>

<style lang="scss" scoped>
.auto-complete {
  display: flex;
  width: calc(100% - 1rem);
  min-height: 2rem;
  background: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: -1rem auto 2rem auto;
  border-radius: 0.5rem;
  flex-direction: column;
}
.auto-complete--item {
  width: 100%;
  padding: 0.75rem;
  cursor: pointer;
  transition: all 0.2s;
  border-bottom: 1px solid rgba(0, 0, 0, 0.075);
}

.auto-complete--item:last-child {
  border-bottom-color: transparent;
}

.auto-complete--item:hover {
  background: #f3f3f3;
}

.no-content {
  display: flex;
  align-items: center;
  padding-left: 0.75rem;
  margin-top: auto;
  margin-bottom: auto;
}

.modal-add-point {
  width: 500px !important;
}
</style>
